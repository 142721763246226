import { Segment } from "semantic-ui-react";
import React, { useEffect, useState, useContext } from "react";

import Helmet from "react-helmet";
import { Grid } from "semantic-ui-react";
import { CustomerGroupSelector } from "../../../common/CustomerGroupSelector/CustomerGroupSelector";
import MobileNotAvailable from "../../../common-mobile/MobileNotAvailable/MobileNotAvailable";
import { getCurrentSalesPeriod } from "../../../lib/apiSalesPeriod";
import { SelectedCustomerGroupContext } from "../../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { SightholderExpressionOfInterest } from "./SightholderExpressionOfInterest";

export const SightholderExpressionOfInterestContainer = () => {
  const [currentSalesPeriod, setCurrentSalesPeriod] = useState(null);
  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);

  useEffect(() => {
      async function fetchData() {
        const currentSalesPeriod = await getCurrentSalesPeriod();
        setCurrentSalesPeriod(currentSalesPeriod);
      }
      fetchData();
    }, []);


  return (
    <>
    <Helmet>
      <title>De Beers Sightholder Portal - Financial Submission</title>
    </Helmet>
    <Grid className="desktop">
      <Grid.Row className="customer-selection-header">
        <Grid.Column width={13}>
          <CustomerGroupSelector hideCustomerSelector={true} />
        </Grid.Column>
        <Grid.Column
          width={3}
          floated="right"
          textAlign="right"
          className="current-sight-heading"
        >
          Current Sight: {currentSalesPeriod?.name}
        </Grid.Column>
        <Grid.Column computer={16} mobile={16}>
          <div className="border"></div>
        </Grid.Column >
      </Grid.Row>
    </Grid>
    <Segment className="desktop">
      <SightholderExpressionOfInterest
        selectedCustomerGroup={selectedCustomerGroup}
      />
    </Segment>
    <MobileNotAvailable />
  </>
  );
};