import "./SightholderExpressionOfInterest.scss";
import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { uploadSightholderEoI } from "./SightholderExpressionOfInterest.functions";

const SightholderExpressionOfInterestUploadModal = ({ 
    customerGroupId, 
    customerGroupName,
    showUploadModal, 
    setShowUploadModal 
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onSelectFileClick = () => {
    document.getElementById("file-input").click();
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) return;

    setIsUploading(true);
    try {
      await uploadSightholderEoI({
        customerGroupId: customerGroupId,
        documentName: customerGroupName,
        setLoadingSubmissionUpload: setIsUploading,
        file: selectedFile,
      });
      setShowUploadModal(false);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Modal open={showUploadModal} size="small" onClose={() => setShowUploadModal(false)}>
      <Modal.Header>Upload Expression of Interest</Modal.Header>
      <Modal.Content>
        <Button primary onClick={onSelectFileClick} disabled={isUploading}>
          Select a file
        </Button>
        <input id="file-input" type="file" hidden onChange={onFileChange} />

        {selectedFile && (
            <div className="add-padding-top">
                <p>
                    {selectedFile.name} will be uploaded by selecting submit.
                </p>
            </div>
        )}
      </Modal.Content>
      <Modal.Actions className="ms-modal-actions">
        <Button
            content="Submit"
            type="submit"
            disabled={!selectedFile || isUploading}
            floated="right"
            onClick={handleSubmit}
            primary
            loading={isUploading}
        />
        <Button
          secondary
          content="Cancel"
          disabled={isUploading}
          onClick={() => setShowUploadModal(false)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SightholderExpressionOfInterestUploadModal;
