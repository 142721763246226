import {
    getCustomerGroupSightholderExpressionOfInterestFile,
    downloadEoITemplate,
    uploadSightholderEoIFile,
    downloadSingleEoI
  } from "../../../lib/apiSightholderExpressionOfInterest";

import { notifyError } from "../../../lib/notifications";

export const getCustomerGroupSightholderEoIData = async ({
    customerGroupId,
    setLoading,
    setTableData,
}) => {
    try {
        setLoading(true);

        var ExpressionOfInterestFile = await getCustomerGroupSightholderExpressionOfInterestFile({
            customerGroupId
        });

        const mappedTableValues = ExpressionOfInterestFile
        ? [{
            sightholderExpressionOfInterestId: ExpressionOfInterestFile.sightholderExpressionOfInterestId,
            documentName: ExpressionOfInterestFile.documentName,
            dateUploaded: ExpressionOfInterestFile.dateUploaded,
          }]
        : [];
        setTableData(mappedTableValues);
        setLoading(false);

    } catch (error) {
        notifyError("Failed to retrieve Expression of Interest file.", error);
    } finally {
        setLoading(false);
    }
};

export const generateExpressionOfInterestTemplate = async ({
    setLoadingTemplateDownload,
}) => {
    setLoadingTemplateDownload(true);
    await downloadEoITemplate();
    setLoadingTemplateDownload(false);
};

export const uploadSightholderEoI = async ({
  customerGroupId,
  documentName,
  setLoadingSubmissionUpload,
  file,
}) => {
  setLoadingSubmissionUpload(true);
  const submission = {
    customerGroupId,
    documentName
  };
  try {
    await uploadSightholderEoIFile(submission, file);
  } catch (error) {
    notifyError("Failed to upload Expression of Interest file.", error);
  } finally {
    setLoadingSubmissionUpload(false);
  }
};

export const downloadSingleSightholderEoI = async (expressionOfInterestId, fileName) => {
    try {
        await downloadSingleEoI(expressionOfInterestId, fileName);
    } catch (err) {
        console.error("Error downloading single EoI:", err);
    }
};
