import {
  CustomerGroupsContext,
  SelectedCustomerContext,
  SelectedCustomerGroupContext,
} from "../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { Suspense, lazy, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import AppLayout from "../components/AppLayout/AppLayout";
import { Cookies } from "./login/Cookies";
import { Finance } from "./profile/Finance";
import { GetOktaConfig } from "../lib/oktaConfig";
import { LoaderComponent } from "../common/LoaderComponent";
import { LoggedInWrapper } from "../common/LoggedInWrapper/LoggedInWrapper";
import Logout from "./login/Logout";
import InsightsContainer from "./profile/Insights/InsightsContainer";
import { MobileExplanRequestAddEdit } from "../common/Ex-PlanRequest/MobileExplanRequestAddEdit";
import { NotificationsContextProvider } from "../components/Notifications/NotificationsContextProvider";
import { OktaAuth } from "@okta/okta-auth-js";
import { Profile } from "./profile/Profile";
import { SightholderExpressionOfInterestContainer } from "./profile/sightholder-expression-of-interest/SightholderExpressionOfInterestContainer";
import SpsLogin from "./login/SpsLogin";
import { TermsConditions } from "./login/TermsConditions";
import { Tutorials } from "./tutorials/Tutorials";
import { tokenUserPermissions } from "../lib/tokenUserPermissions";
import { CommunicationsContextProvider } from "../components/Communications/CommunicationsContextProvider";
import { ReportsContainer } from "./reports/ReportsContainer";

const CurrentSight = lazy(() => import("./current-sight/CurrentSight"));
const SignIn = lazy(() => import("./login/SignIn"));
const ResetPasswordForm = lazy(() => import("./login/ResetPasswordForm"));
const UnlockAccountForm = lazy(() => import("./login/UnlockAccountForm"));
const HelpScreen = lazy(() => import("./login/HelpScreen"));

const Invoices = lazy(() => import("./invoices/Invoices"));
const PreInvoiceDetails = lazy(() =>
  import("./invoices/preInvoiceDetails/PreInvoiceDetailsContainer")
);
const Applications = lazy(() => import("./applications/Applications"));
const ChangeRequest = lazy(() => import("./change-request/ChangeRequest"));
const ItoYearOverview = lazy(() =>
  import("./ito-year-overview/ItoYearOverview")
);
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const OktaErrorHandler = lazy(() => import("./login/OktaErrorHandler"));
const OrdersIndexContainer = lazy(() =>
  import("./orders/OrdersIndexContainer")
);
const CapturePDS = lazy(() => import("./orders/CapturePDS/CapturePDS"));
const CaptureMidTermPDS = lazy(() =>
  import("./orders/CaptureMidTermPDS/CaptureMidTermPDS")
);

const NotAuthenticatedContainer = lazy(() =>
  import("./not-authenticated/NotAuthenticatedContainer")
);
const NotAuthorisedContainer = lazy(() =>
  import("./not-authorised/NotAuthorisedContainer")
);
const NotFoundContainer = lazy(() => import("./not-found/NotFoundContainer"));

const requireAuth = (Module, permission) => (props) => {
  return (
    <LoggedInWrapper key={props.location.pathname} permission={permission}>
      <Module {...props} />
    </LoggedInWrapper>
  );
};

export const Routes = () => {
  const [customerGroups, setCustomerGroups] = useState([]);
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const customerGroupsValue = {
    customerGroups: customerGroups,
    setCustomerGroups: setCustomerGroups,
  };
  const selectedCustomerGroupValue = {
    selectedCustomerGroup: selectedCustomerGroup,
    setSelectedCustomerGroup: setSelectedCustomerGroup,
  };
  const selectedCustomerValue = {
    selectedCustomer: selectedCustomer,
    setSelectedCustomer: setSelectedCustomer,
  };

  const loginCallback = () => {
    return <LoginCallback errorComponent={OktaErrorHandler} />;
  };

  const history = useHistory();
  const onAuthRequired = () => {
    history.push("/login");
  };

  const oktaAuth = new OktaAuth({ onAuthRequired, ...GetOktaConfig() });
  return (
    <Security oktaAuth={oktaAuth}>
      {/* This is not a mistake Route path="/okta-login" must live outside of AppLayout otherwise bad things happen */}
      <Route path="/okta-login" render={loginCallback} />
      <Route exact path="/logout" component={Logout} />
      <SpsLogin>
        <NotificationsContextProvider>
          <CommunicationsContextProvider>
            <AppLayout>
              <CustomerGroupsContext.Provider value={customerGroupsValue}>
                <SelectedCustomerGroupContext.Provider
                  value={selectedCustomerGroupValue}
                >
                  <SelectedCustomerContext.Provider
                    value={selectedCustomerValue}
                  >
                    <Suspense fallback={<LoaderComponent loading={true} />}>
                      <Switch>
                        <Route exact path="/" render={() => <SignIn />} />
                        <Route path="/login" render={() => <SignIn />} />
                        <Route
                          path="/forgot-password"
                          render={() => <SignIn />}
                        />
                        <Route
                          path="/reset-password"
                          render={() => <ResetPasswordForm />}
                        />
                        <Route
                          path="/unlock-account"
                          render={() => <UnlockAccountForm />}
                        />
                        <Route exact path="/help" component={HelpScreen} />
                        <Route
                          exact
                          path="/termsconditions"
                          component={TermsConditions}
                        />
                        <Route exact path="/cookies" component={Cookies} />

                        <Route
                          exact
                          path="/orders"
                          render={requireAuth(
                            OrdersIndexContainer,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/insights"
                          render={requireAuth(
                            InsightsContainer,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        {/*  temporarily hide actions on the ITO screen 
                    <Route
                      exact
                      path="/orders/view/:orderId"
                      render={requireAuth(
                        OrdersViewContainer,
                        tokenUserPermissions.CUSTOMER_PORTAL
                      )}
                    />
                    <Route
                      exact
                      path="/orders/edit/:orderId"
                      render={requireAuth(
                        OrdersEditContainer,
                        tokenUserPermissions.CUSTOMER_PORTAL
                      )}
                    /> */}
                        <Route
                          exact
                          path="/orders/capture-pds"
                          render={requireAuth(
                            CapturePDS,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          exact
                          path="/orders/capture-midterm-pds"
                          render={requireAuth(
                            CaptureMidTermPDS,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          exact
                          path="/not-authenticated"
                          component={NotAuthenticatedContainer}
                        />
                        <Route
                          exact
                          path="/not-authorised"
                          component={NotAuthorisedContainer}
                        />
                        <Route
                          exact
                          path="/dashboard"
                          render={requireAuth(
                            Dashboard,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/current-sight/explanmobile/:customerId/:orderType"
                          render={requireAuth(
                            MobileExplanRequestAddEdit,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/current-sight/explanmobile"
                          render={requireAuth(
                            MobileExplanRequestAddEdit,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/current-sight/:tab"
                          render={requireAuth(
                            CurrentSight,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/current-sight"
                          render={requireAuth(
                            CurrentSight,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/invoices/preinvoicedetails/:salesPeriodId/:customerId/:salesPeriodName"
                          render={requireAuth(
                            PreInvoiceDetails,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/invoices"
                          render={requireAuth(
                            Invoices,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/applications/:tab"
                          render={requireAuth(
                            Applications,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/applications"
                          render={requireAuth(
                            Applications,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/change-request"
                          render={requireAuth(
                            ChangeRequest,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/ito-year-overview"
                          render={requireAuth(
                            ItoYearOverview,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/profile"
                          render={requireAuth(
                            Profile,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/finance"
                          render={requireAuth(
                            Finance,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                          path="/tutorials"
                          render={requireAuth(
                            Tutorials,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route
                            path="/reports"
                            render={requireAuth(
                                ReportsContainer,
                                tokenUserPermissions.CUSTOMER_PORTAL
                            )}
                        />
                       <Route
                          path="/sightholder-expression-of-interest/SightholderExpressionOfInterest"
                          render={requireAuth(
                            SightholderExpressionOfInterestContainer,
                            tokenUserPermissions.CUSTOMER_PORTAL
                          )}
                        />
                        <Route component={NotFoundContainer} />
                      </Switch>
                    </Suspense>
                  </SelectedCustomerContext.Provider>
                </SelectedCustomerGroupContext.Provider>
              </CustomerGroupsContext.Provider>
            </AppLayout>
          </CommunicationsContextProvider>
        </NotificationsContextProvider>
      </SpsLogin>
    </Security>
  );
};
