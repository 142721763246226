import "./SightholderExpressionOfInterest.scss";
import { Divider, Grid } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { IconButton } from "../../../common/IconButton/IconButton";
import { IndexPageTable } from "../../../common/IndexPageTable/IndexPageTable";
import {
    UilDownloadAlt,
    UilExport
  } from "@iconscout/react-unicons";

import {
    fetchCustomerGroup
} from "../BankList.functions";
import {
    generateExpressionOfInterestTemplate,
    getCustomerGroupSightholderEoIData,
    downloadSingleSightholderEoI
} from "./SightholderExpressionOfInterest.functions";

import SightholderExpressionOfInterestUploadModal from "./SightholderExpressionOfInterestUploadModal";

import { StandardSubheading } from "../../../common/StandardSubheading/StandardSubheading";
import { validateRole } from "../../../lib/validateRole";
import { tokenUserPermissions } from "../../../lib/tokenUserPermissions";

export const SightholderExpressionOfInterest = ({
  selectedCustomerGroup,
}) => {
  const [customerGroup, setCustomerGroup] = useState();
  const [customerGroupName, setCustomerGroupName] = useState();
  const [loadingTemplateDownload, setLoadingTemplateDownload] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadingSubmissionModal, setUploadingSubmissionModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [hasUploadPermission, setHasUploadPermission] = useState(false);
  const [hasDownloadPermission, setHasDownloadPermission] = useState(false);

  const fieldNameMappings = {
    DOCUMENTNAME: { name: "Document Name", order: 1 },
    DATEUPLOADED: { name: "Date Uploaded", order: 2 },
  };

  const dateFields = ["DATEUPLOADED"];

  const fieldsToIgnore = ["SIGHTHOLDEREXPRESSIONOFINTERESTID"];

  const actionIdField = "sightholderexpressionofinterestId";

  const actionPaths = {};
 
  if(hasDownloadPermission) {
    actionPaths.Download = {
      onClick: (data) => {
          downloadSingleSightholderEoI(
          data.sightholderExpressionOfInterestId,
          data.documentName
        );
      },
      icon: <UilDownloadAlt size={25} />,
    };
  }

    useEffect(() => {
      setHasUploadPermission(validateRole(tokenUserPermissions.UPLOAD_SIGHTHOLDER_EXPRESSION_OF_INTEREST.POST));
      setHasDownloadPermission(validateRole(tokenUserPermissions.DOWNLOAD_SIGHTHOLDER_EXPRESSION_OF_INTEREST.GET));
    }, []);

  const headerContent = (
    <React.Fragment>
      <Grid.Row className="table-header">
        <Grid.Column width={6}>
            <StandardSubheading>
                <div className="add-padding-bottom">
                    Expression of Interest: {customerGroup && customerGroup.name}
                </div>
            </StandardSubheading>
        </Grid.Column>
        <Grid.Column width={10}>
        {hasUploadPermission && (
          <IconButton
              primary
              floated="right"
              Icon={UilExport}
              onClick={() => setShowUploadModal(true)}
            >
              Upload Expression of Interest Data
            </IconButton>
        )}
          <IconButton
            floated="right"
            secondary
            Icon={UilDownloadAlt}
            onClick={() => {
                generateExpressionOfInterestTemplate({ setLoadingTemplateDownload });
            }}
            loading={loadingTemplateDownload}
          >
            Download Template
          </IconButton>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );

  useEffect(() => {
    const fetchSightholderExpressionOfInterestData = async () => {
        if(selectedCustomerGroup) {
            setCustomerGroup();

            await fetchCustomerGroup({
                setCustomerGroup,
                customerGroupId: selectedCustomerGroup
            });

            await getCustomerGroupSightholderEoIData({
                customerGroupId: selectedCustomerGroup,
                setLoading: setLoadingData,
                setTableData: setTableData
            });
        }
    };

    fetchSightholderExpressionOfInterestData();
    
  }, [selectedCustomerGroup, showUploadModal]);

  useEffect(() => {
    if(customerGroup){
      setCustomerGroupName(customerGroup.name);
    }
  }, [customerGroup]);

  const pageContent = (
    <React.Fragment>
      <Divider />

      {showUploadModal && (
        <SightholderExpressionOfInterestUploadModal
          customerGroupId={selectedCustomerGroup}
          customerGroupName={customerGroupName}
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
          uploadingSubmissionModal={uploadingSubmissionModal}
          setUploadingSubmissionModal={setUploadingSubmissionModal}
        />
      )}
        <IndexPageTable
            tableData={tableData}
            fieldNameMappings={fieldNameMappings}
            fieldsToIgnore={fieldsToIgnore}
            actionIdField={actionIdField}
            actionPaths={actionPaths}
            headerContent={headerContent}
            showFilters={false}
            dateFields={dateFields}
            options={{ disablePagination: true }}
            onSortColumnChange={() => {}}
            loading={loadingData}
        />
    </React.Fragment>
  );

  return pageContent;
};