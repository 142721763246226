export const tokenUserPermissions = {
  CUSTOMER_PORTAL: "customerPortal",
  REPRESENTATIVE_TYPES: { CLIENT: "Client", BROKER: "Broker" },
  MANUFACTURING_DATA: { GET: "ms:get" },
  ORDER_MANAGEMENT_DATA: { ALL: "om:all" },
  BOX_INSIGHTS: { GET: "bxi:get" },
  VIEW_EMBEDDED_REPORTS: { GET: "cEmbeddedReports:get" },
  VIEW_SIGHTHOLDER_EXPRESSION_OF_INTEREST: { GET: "cPorAppEoI:view" },
  UPLOAD_SIGHTHOLDER_EXPRESSION_OF_INTEREST: { POST: "cPorAppEoI:upload" },
  DOWNLOAD_SIGHTHOLDER_EXPRESSION_OF_INTEREST: { GET: "cPorAppEoI:download" },
};
