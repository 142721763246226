import { createAxios } from "./axiosWrapper";
import { notifyError, notifySuccess } from "./notifications";

const orderManagementUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;

const SightholderExpressionOfInterestController = () =>
  createAxios(`${orderManagementUrl}/SightholderExpressionOfInterest`);

export const downloadEoITemplate = async () => {
  try {
    const result = await SightholderExpressionOfInterestController().get(
      `download-eoi-template`,
      { responseType: "blob" }
    );
    const url = window.URL.createObjectURL(result.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = "SightholderExpressionOfInterestTemplate.xlsx";
    a.click();
    return result.data;
  } catch (err) {
    notifyError(
      "Could not download expression of interest template.",
      err
    );
  }
};

export const getCustomerGroupSightholderExpressionOfInterestFile = async ({ customerGroupId }) => {
  try {
    const request = `get-eoi-file?customerGroupId=${customerGroupId}`;

    const sightholderExpressionOfInterestFile = await SightholderExpressionOfInterestController().get(
      request
    );
    return sightholderExpressionOfInterestFile.data;
  } catch (err) {
    notifyError("Could not fetch Expression of Interest file.", err);
  }
};

export const uploadSightholderEoIFile = async (newSubmission, file) => {
  try {
    const data = new FormData();
    data.append("customerGroupId", newSubmission.customerGroupId);
    data.append("documentName", newSubmission.documentName);
    data.append("file", file);
    
    await SightholderExpressionOfInterestController().post(
      "upload",
      data
    );
    return notifySuccess("Successfully uploaded expression of interest file.");
  } catch (err) {
    notifyError("Could not upload sightholder expression of interest file.", err);
  }
};

export const downloadSingleEoI = async (expressionOfInterestId, fileName) => {
  try {
      const result = await SightholderExpressionOfInterestController().get(
          `download-eoi?expressionOfInterestId=${expressionOfInterestId}`,
          { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(result.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName+".xlsx" || "SightholderExpressionOfInterest.xlsx";
      a.click();
      return result.data;
  } catch (err) {
      notifyError("Could not download expression of interest file.", err);
  }
};